<template>
  <div id="tableSubContainer">
      <v-card v-if="totalRows !== 0" class="card">
        <div class="flex-container w45 jcsb">
          <div v-if="columns.length > 0 && showSelectColumns" class="center-container">
        <Select
          :name="'Select Columns'"
          :is-multi-select="true"
          v-model="selectedColumns"
          :allOptions="columns"
          :placeholderText="'Select Columns'"
          :selectedText="'columns'"
        />
    </div>
        <div v-if="tableRows && tableRows.length > 0" class="center-container dark-text">
      {{`${$t('displaying_patient_filters')} ` }} <div class="pd-lr">{{ `${$t(tableRows.length)} `}}</div> {{ `${$t('of_patient_filters')}` }} <div class="pd-lr">{{ `${$t(totalRows)}` }}</div> {{`${$t(displayFieldValue)}`}}

    </div>
        <div class="center-container">
    <PageTurner
      @changePageEvent="changePage"
      :pageSize="pageSize"
      :totalItems="totalRows"
      :currentPage="selectedPage"
      :pageTurnerSize="pageTurnerSize"
    />
    </div>
  </div>
          <Table
            :searchable="false"
            v-if="!detailsLoading"
            :columns="tableColumnsData"
            :data="tableRows"
            @actionButtonEvent="handleActionClick"
            @clickTableEvent="clickTableEvent"
            :pageSize="50"
            :loading="detailsLoading" />
      </v-card>

  </div>
</template>

<script>

import Table from '../../../../shared/components/Table.vue'
import PageTurner from '../../../../shared/components/PageTurner.vue'
import Select from '../../../../shared/components/Select.vue'

export default {
  name: 'DiagnosticsDetailsPage',
  components: {
    Table,
    PageTurner,
    Select
  },
  props: {
    detailsLoading: {
      type: Boolean
    },
    selectedPage: {
      type: Number
    },
    tableRows: {
      type: Array
    },
    columns: {
      type: Array
    },
    totalRows: {
      type: Number
    },
    pageSize: {
      type: Number
    },
    showSelectColumns: {
      type: Boolean,
      default: true
    },
    displayFieldValue: {
      type: String
    }
  },
  data: function () {
    return {
      selectedColumns: [],
      tableColumnsData: [],
      tableRowsData: [],
      pageTurnerSize: 5
    }
  },
  mounted: function () {
    if (this.columns.length > 0) {
      this.selectedColumns = this.filterColumns(this.columns)
    } if (this.columns.length >= 9) {
      this.selectedColumns = this.filterColumns(this.columns.slice(0, 9))
    }
  },
  methods: {
    filterColumns (arr) {
      return arr.filter(ele => !!ele).map((ele, id) => ({ Key: ele.Key, Value: ele.Value, Sortable: ele.Sortable, Clickable: ele.Clickable }))
    },
    changePage (number) {
      this.$emit('turn-page', number)
    },
    handleActionClick (item, action) {
      this.$emit('handle-action', item, action)
    },
    clickTableEvent (column, item) {
      this.$router.push(`/dashboard/patient/${item.id !== undefined ? item.id : item.episodeId}/tests`)
    }
  },
  watch: {
    columns () {
      if (this.columns.length > 0) {
        this.selectedColumns = this.filterColumns(this.columns)
      } if (this.columns.length >= 9) {
        this.selectedColumns = this.filterColumns(this.columns.slice(0, 9))
      }
    },
    selectedColumns (nv, ov) {
      if (nv.length !== 0) {
        var tc = this.selectedColumns.map(item => ({
          key: item.Key,
          name: item.Value,
          sortable: item.Sortable,
          clickable: item.Clickable
        }))
        this.tableColumnsData = tc
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-info {
margin-top: 16px;
font-weight: bold;
}
.multi-cal-table {
font-size: 14px;
}
.table_container {
margin: 0px !important;
}
.card {
padding: 15px;
border-radius: 8px;
background-color: var(--background-color-primary-light-dark);
}
.button-container {
justify-content: flex-end;
display: flex;
}
.center-container {
display: flex;
align-items: center;
}

.pd-lr {
padding-right: 10px;
padding-left: 10px;
font-weight: bolder;
}

.jcsb {
justify-content: space-between;
}
.flex-container {
display: flex;
}
</style>
