<template>
  <Modal :headingText="$t('_test_details')" :hideCancelBtn="true" :hideConfirmBtn="true" @close="close()">
    <div slot="body">
      <p class="text-ibm-plex-sans loading" v-if="testFullDetailsLoading">
        {{ $t('Loading') }} <font-awesome-icon icon="spinner" class="fa-lg" spin />
      </p>
      <div v-if="testSummary">
        <p class="bold-text">{{ $t('_test_summary') }}</p>
        <div v-for="testSummaryItem in testSummary" :key="testSummaryItem.key" class="test-data">
          <p>{{ $t(testSummaryItem.key) }}</p>
          <div>{{ testSummaryItem.value == null ? "-" : $t(testSummaryItem.value) }}</div>
        </div>
        <hr class="section-divider" />
      </div>

      <div v-if="testSamples">
        <div v-for="(result, index) in testSamples" :key="'sample-' + index">
          <p class="bold-text">{{ $t('_sample') }} {{ index + 1 }}</p>
          <div v-for="sampleItem in result" :key="sampleItem.key" class="test-data">
            <p>{{ $t(sampleItem.key) }}</p>
            <div>{{ sampleItem.value == null ? "-" : $t(sampleItem.value) }}</div>
          </div>
        </div>
        <hr class="section-divider" />
      </div>

      <div v-if="testResult">
        <p class="bold-text">{{ $t('_test_result') }}</p>
        <div v-for="testResultItem in testResult" :key="testResultItem.key" class="test-data">
          <p>{{ $t(testResultItem.key) }}</p>
          <div>{{ testResultItem.value == null ? "-" : $t(testResultItem.value) }}</div>
        </div>
        <hr class="section-divider" />
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '../../../../shared/components/Modal.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'TestDetails',
  components: {
    Modal
  },
  computed: {
    ...mapState('Diagnostics', [
      'testSummary',
      'testSamples',
      'testResult',
      'testFullDetailsLoading'
    ])
  },
  methods: {
    ...mapActions('Diagnostics', [
      'fetchTestDetails'
    ]),
    close () {
      this.$emit('test-details-closed')
    }
  }
}
</script>

<style  scoped>
.loading {
    text-align: center;
    margin-top: 10px !important;
    margin-bottom: 5px !important;
    color: var(--text-primary-color)
}
.test-data {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 15px;
}
.bold-text {
  font-weight: bold;
}
.section-divider {
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
