<template>
  <div id="testDetailsTableSubContainer">
    <div class="pull-right" >
      <Button id="addBtn" @click="openAddTest" :label="$t(`_add_test`)"> </Button>
    </div>
    <p class="text-ibm-plex-sans loading" v-if="testDetailsLoading">{{ $t('Loading') }}  <font-awesome-icon icon="spinner" class="fa-lg" spin />
      </p>
      <div v-if="!testDetailsLoading && totalTests != 0">
        <DiagnosticsDetailsPageVue
          :displayFieldValue="'_tests'"
          :showSelectColumns="false"
          :detailsLoading="testDetailsLoading"
          :selectedPage="selectedPage"
          :tableRows="testsRowsData"
          :columns="testsColumnsData"
          :totalRows="totalTests"
          :pageSize="pageSize"
          @turn-page="changePage"
          @handle-action="handleActionClick"
        />
      </div>
      <div v-if="!testDetailsLoading && totalTests === 0">
        <div class="text-container" >
          <p> {{$t("click_on_add_test_to_add_test_for_patient")}}</p>
        </div>
      </div>
      <div v-if="showTestDetails">
        <TestDetails
          @test-details-closed="closeTestDetails()"
        />
      </div>
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import DiagnosticsDetailsPageVue from '../../diagnostics/components/DiagnosticsDetailsPage.vue'
import Button from '../../../../shared/components/Button.vue'
import TestDetails from '../../diagnostics/components/TestDetails.vue'

export default {
  name: 'DiagnosticsTestsPage',
  components: {
    DiagnosticsDetailsPageVue,
    Button,
    TestDetails
  },
  data: function () {
    return {
      pageTurnerSize: 5,
      testRequestId: null,
      item: null,
      showTestDetails: false
    }
  },
  async mounted () {
    this.setSearchKey('episodeId')
    this.setSearchValue(this.patientId)
    await this.loadTestListForPatientPage()
  },
  computed: {
    ...mapState('Diagnostics', [
      'testDetailsLoading',
      'testsColumnsData',
      'testsRowsData',
      'totalTests',
      'numberPages',
      'selectedPage',
      'pageSize'
    ]),
    ...mapState('Patient', ['patientId'])
  },
  methods: {
    ...mapActions('Diagnostics', [
      'loadTestListForPatientPage',
      'setCurrentPage',
      'setSearchKey',
      'setSearchValue',
      'fetchTestDetails',
      'setTestRequestId'
    ]),
    async changePage (number) {
      if (number < 1 || number > this.numberPages) {
        return
      }
      this.setCurrentPage(number)
      await this.loadTestListForPatientPage()
    },
    async handleActionClick (item, action) {
      if (action.displayKey === '_view_test') {
        this.showTestDetails = true
        await this.fetchTestDetails(action)
      } else if (action.displayKey === '_edit_test') {
        this.setTestRequestId(item.testRequestId)
        this.$router.push(`/dashboard/diagnostics/edit-test/${item.testRequestId}`)
      }
    },
    closeTestDetails () {
      this.showTestDetails = false
    },
    openAddTest () {
      this.$router.push(`/dashboard/diagnostics/add-test/${this.patientId}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.pull-right {
  justify-content: flex-end;
  display: flex;
  margin: 16px;
}

.text-container {
  background-color: var(--background-color-primary-light-dark);
  padding: 10px 20px 30px 10px;
  letter-spacing: 0.015em;
  box-sizing: border-box;
  border-radius: 0px 12px 12px 12px;
  color: var(--text-primary-color);
}
</style>
